import * as React from 'react'
import './styles.scss'
import Header from './header'
import Footer from './footer'

const ResumePage = () => {
	return (
		<main>
			<head>
				<title>Resume</title>
			</head>
			<meta
				name='viewport'
				content='width=device-width, height=device-height, initial-scale=1.0, minimum-scale=1.0'
			/>
			<link
				rel='stylesheet'
				href='https://use.fontawesome.com/releases/v5.7.2/css/all.css'
				integrity='sha384-fnmOCqbTlWIlj8LyTjo7mOUStjsKC4pOpQbqyi7RrhN7udi9RwhKkMHpvLbHG9Sr'
				crossorigin='anonymous'
			/>
			<div className='boxWidth resumeBottom'>
				<Header />
				<div className='userText resumeContainer noBox'>
					<h4 className='resumeTitle mt-3'>Resume</h4>
					<div className='title-overflow'>
						<p></p>
						<a href={"/static/prash-keth-resume.pdf"} target='_blank' className='link'>
							[View Resume]
						</a>
						<a href={"/static/prash-keth-resume.pdf"} download className='link ml-2'>
							[Download Resume]
						</a>
					</div>
					<div>
						<p></p>
						<h5 className='mt-1'>Education</h5>
						<div className='title-overflow'>
							<p className='name-title'>University of Toronto</p>
							<p className='date-title'>
								{' '}
								(2018-2023) <br />
								BSc. Computer Science Co-op - Software Engineering Stream
								<br />
								UofT Entrance Scholarship, Dean's List (2019)
							</p>
						</div>
						<p></p>
						<h5>Experience</h5>
						<div className='title-overflow'>
							<p className='name-title'>Facebook</p>
							<p className='date-title'>
								{' '}
								(New York, February 2024 - Present) <br />
								Software Engineer
							</p>
						</div>
						<p></p>
						<div className='title-overflow'>
							<p className='name-title'>Zynga</p>
							<p className='date-title'>
								{' '}
								(Toronto, July 2023 - January 2024) <br />
								Software Engineer
							</p>
						</div>
						<p></p>
						<div className='title-overflow'>
							<p className='name-title'>Facebook</p>
							<p className='date-title'>
								{' '}
								(Menlo Park, Fall 2022) <br />
								Software Engineer Intern
							</p>
						</div>
						<p></p>
						<div className='title-overflow'>
							<p className='name-title'>Amazon</p>
							<p className='date-title'>
								{' '}
								(Toronto, Summer 2022) <br />
								Software Development Engineer Intern
							</p>
						</div>
						<p></p>
						<div className='title-overflow'>
							<p className='name-title'>Appnovation</p>
							<p className='date-title'>
								{' '}
								(Toronto, Sept 2020 - Apr 2021) <br />
								Software Developer Intern
							</p>
						</div>
						{/* <p></p>
						<div className='title-overflow'>
							<p className='name-title'>LIVE Competition</p>
							<p className='date-title'>
								{' '}
								(Toronto, Summer 2020) <br />
								Software Developer Intern
							</p>
						</div> */}
						<p></p>
						<div className='title-overflow'>
							<p className='name-title'>CIBC</p>
							<p className='date-title'>
								{' '}
								(Toronto, Summer 2019) <br />
								Software Developer Intern
							</p>
						</div>
						{/* <p></p>
						<div className='title-overflow'>
							<p className='name-title'>Mathnasium</p>
							<p className='date-title'>
								{' '}
								(Toronto, Dec 2017 - Apr 2020) <br />
								Math Instructor
							</p>
						</div> */}
					</div>
				</div>
			</div>
			<Footer />
		</main>
	)
}

export default ResumePage
